import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useMonitorContext } from "../providers/MonitorContext";
import { trunksRelations } from "../helpers/trunksRelations";

export default function DepartmentSelector() {
  const { setDepartmentsFilter } = useMonitorContext();

  return (
    <Autocomplete
      disablePortal
      id="combo-box-demo"
      options={
        trunksRelations
          ?.sort((a, b) => (a.name > b.name ? 0 : -1))
          .map((d) => ({
            label: d.name,
            id: d.accountSearchId,
          })) || []
      }
      sx={{ width: 300 }}
      renderInput={(params) => <TextField {...params} label="Troncal" />}
      size="small"
      onChange={(e, v) =>
        setDepartmentsFilter(
          trunksRelations.find((tr) => tr.accountSearchId === v?.id)
        )
      }
    />
  );
}
