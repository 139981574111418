export enum TimeEvents {
  Open = "open",
  Pause = "pause",
  Close = "close",
}

export enum BreakTypes {
  Break,
  Lunch,
  Bathroom,
}

export type TimeData = {
  userIds: number[];
  breakType?: BreakTypes;
};

export type TimeEvent = {
  event: TimeEvents;
  data: TimeData;
  ts: number;
};
