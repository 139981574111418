export enum SocketEventTypes {
  CallInit,
  CallStart,
  CallEnd,
  TimemanOpen,
  TimemanPause,
  TimemanClose,
  FormtimeStarted,
  FormtimeFinished,
}

export type SocketEvent = {
  eventType: SocketEventTypes;
  data: object;
};
