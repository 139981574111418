export enum FormEvents {
  Started = "started",
  Finished = "finished",
}

export type FormtimeData = {
  userId: number;
};

export type FormtimeEvent = {
  event: FormEvents;
  data: FormtimeData;
  ts: number;
};
