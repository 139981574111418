import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Report } from "../types/reportType";
import axiosInstance from "../helpers/hostAxios";
import { SocketEvent, SocketEventTypes } from "../types/eventType";
import { CallEvent } from "../types/phoneType";
import { TimemanStatus } from "../bitrix/models/timemanEntity";
import { TimeEvent } from "../types/timemanType";
import { TrunkRelation } from "../helpers/trunksRelations";
import { useSnackbar } from "./SnackbarContext";
import { FormtimeEvent } from "../types/formtimeType";

type MonitorContextType = {
  report: Report | undefined;
  setInitialReport: (report: Report) => void;
  departmentsFilter: TrunkRelation | undefined;
  setDepartmentsFilter: React.Dispatch<
    React.SetStateAction<TrunkRelation | undefined>
  >;
  timemanRecords: TimeEvent[] | undefined;
  timemanStatuses: TimemanStatus[] | undefined;
  formtimeRecords: FormtimeEvent[] | undefined;
};

export const MonitorContext = createContext<MonitorContextType | null>(null);

type MonitorProviderProps = {
  children: React.ReactNode;
};

export function MonitorProvider({ children }: MonitorProviderProps) {
  const { openSnackbar } = useSnackbar();
  const [report, setReport] = useState<Report>();
  const [timemanRecords, setTimemanRecords] = useState<TimeEvent[]>();
  const [timemanStatuses, setTimemanStatuses] = useState<TimemanStatus[]>();
  const [formtimeRecords, setFormtimeRecords] = useState<FormtimeEvent[]>();
  const [departmentsFilter, setDepartmentsFilter] = useState<
    TrunkRelation | undefined
  >();
  const reportRef = useRef(report);
  const timemanRecordsRef = useRef(timemanRecords);
  const formtimeRecordsRef = useRef(formtimeRecords);

  // Actualiza la referencia cada vez que el estado 'report' cambie
  useEffect(() => {
    reportRef.current = report;
  }, [report]);
  useEffect(() => {
    timemanRecordsRef.current = timemanRecords;
  }, [timemanRecords]);
  useEffect(() => {
    formtimeRecordsRef.current = formtimeRecords;
  }, [formtimeRecords]);

  const connectToWebSocket = useCallback(() => {
    const ws = new WebSocket(process.env.REACT_APP_WEB_SOCKET_HOST as string); //"ws://localhost:3000"

    const connectWebSocket = () => {
      ws.onmessage = (event) => {
        const currentReport = reportRef.current;
        const currentTimemanRecords = timemanRecordsRef.current;
        const currentFormtimeRecords = formtimeRecordsRef.current;
        const eventReceived: SocketEvent = JSON.parse(event.data);

        // Report events
        if (currentReport) {
          if (eventReceived.eventType === SocketEventTypes.CallInit) {
            setReport({
              ...currentReport,
              calls: [...currentReport.calls, eventReceived.data],
            });
          } else if (
            eventReceived.eventType === SocketEventTypes.CallStart ||
            eventReceived.eventType === SocketEventTypes.CallEnd
          ) {
            setReport({
              ...currentReport,
              calls: currentReport.calls.map((c) => {
                if (
                  (c as CallEvent).callId ===
                  (eventReceived.data as CallEvent).callId
                ) {
                  return eventReceived.data;
                } else {
                  return c;
                }
              }),
            });
          }
        }

        // Timeman events
        if (currentTimemanRecords) {
          if (
            eventReceived.eventType === SocketEventTypes.TimemanOpen ||
            eventReceived.eventType === SocketEventTypes.TimemanPause ||
            eventReceived.eventType === SocketEventTypes.TimemanClose
          ) {
            // console.log("open", (eventReceived.data as any).userIds);
            setTimemanRecords([
              ...currentTimemanRecords,
              eventReceived.data as TimeEvent,
            ]);
          }
        }

        // Formtime events
        if (currentFormtimeRecords) {
          if (
            eventReceived.eventType === SocketEventTypes.FormtimeStarted ||
            eventReceived.eventType === SocketEventTypes.FormtimeFinished
          ) {
            // console.log("open", (eventReceived.data as any).userIds);
            setFormtimeRecords([
              ...currentFormtimeRecords,
              eventReceived.data as FormtimeEvent,
            ]);
          }
        }
      };

      ws.onopen = () => {
        console.log("Connected to the WebSocket server");
      };

      ws.onclose = () => {
        // console.log(
        //   "Socket disconnected, trying to reconnect...",
        //   new Date().toLocaleString()
        // );
        // connectToWebSocket();
        openSnackbar(
          "Debe recargar la página para ver el monitor actualizado.",
          "error"
        );
      };
    };

    connectWebSocket();

    setInterval(() => {
      if (ws.readyState === WebSocket.OPEN) {
        ws.send("ping");
      }
    }, 10000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setInitialReport = useCallback((report: Report) => {
    setReport(report);
    axiosInstance
      .get("/timeman/list-records")
      .then((responseTimeEvents: { data: TimeEvent[] }) => {
        setTimemanRecords(responseTimeEvents.data);

        const timeEventsUserIds = responseTimeEvents.data.reduce(
          (prev: number[], curr) => [...prev, ...curr.data.userIds],
          []
        );
        const uniqueUserIds = Array.from(new Set(timeEventsUserIds));

        axiosInstance
          .post("/timeman/list-not-initialized-times", {
            userIds: report.users
              .filter((u) => !uniqueUserIds.includes(Number(u.ID)))
              .map((u) => Number(u.ID)),
          })
          .then((responseTimeStatuses: { data: TimemanStatus[] }) => {
            setTimemanStatuses(responseTimeStatuses.data);
          });
      });
    axiosInstance
      .get("/formtime/list-records")
      .then((responseFormEvents: { data: FormtimeEvent[] }) => {
        setFormtimeRecords(responseFormEvents.data);
      });
  }, []);

  useEffect(() => {
    axiosInstance
      .get("/report/last-version-report")
      .then((response) => setInitialReport(response.data));
  }, [setInitialReport]);

  useEffect(() => {
    connectToWebSocket();
  }, [connectToWebSocket]);

  const memoizedValue = useMemo(
    () => ({
      report,
      setInitialReport,
      departmentsFilter,
      setDepartmentsFilter,
      timemanRecords,
      timemanStatuses,
      formtimeRecords,
    }),
    [
      report,
      setInitialReport,
      departmentsFilter,
      setDepartmentsFilter,
      timemanRecords,
      timemanStatuses,
      formtimeRecords,
    ]
  );
  return (
    <MonitorContext.Provider value={memoizedValue}>
      {children}
    </MonitorContext.Provider>
  );
}

export const useMonitorContext = () => {
  const context = useContext(MonitorContext);

  if (!context)
    throw new Error(
      "useMonitorContext context must be use inside MonitorProvider"
    );

  return context;
};
