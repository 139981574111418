import React from "react";
import { Grid, Stack } from "@mui/material";
import MonitorTable from "./components/MonitorTable/MonitorTable";
import Navbar from "./components/Navbar";
import { MonitorProvider } from "./providers/MonitorContext";
import { SnackbarProvider } from "./providers/SnackbarContext";

function App() {
  return (
    <SnackbarProvider>
      <MonitorProvider>
        <Navbar>
          <Stack sx={{ mt: 4, ml: 4, mr: 4 }}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <MonitorTable />
              </Grid>
            </Grid>
          </Stack>
        </Navbar>
      </MonitorProvider>
    </SnackbarProvider>
  );
}

export default App;
