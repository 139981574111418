/* eslint-disable no-undef */
const bearerTokenHD = "bWF4aWFwaTp1SG5CU0QyMWRz";

const getCallCardStatusHD = async () =>
  new Promise((resolve, reject) => {
    try {
      BX24.placement.call("getStatus", {}, function (result) {
        resolve(result);
      });
    } catch (e) {
      reject(e);
    }
  });

const getUserIdHD = async () =>
  new Promise((resolve, reject) => {
    try {
      BX24.callMethod("user.current", {}, function (res) {
        resolve(res.data().ID);
      });
    } catch (e) {
      reject(e);
    }
  });

const runHDProcess = async () => {
  console.log("Call Background Worker initialized");

  const userId = await getUserIdHD();

  BX24.placement.bindEvent("CallCard::CallStateChanged", function (callState) {
    console.log("CallCard::CallStateChanged", callState);

    if (callState === "idle") {
      getCallCardStatusHD().then((callCardStatus) => {
        fetch("https://maxirest-monitor-api.hiperdevs.com/phone/idle", {
          method: "POST",
          headers: {
            Authorization: `Basic ${bearerTokenHD}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ callCardStatus, userId }),
        });
      });
    } else if (callState === "connected") {
      getCallCardStatusHD().then((callCardStatus) => {
        if (callCardStatus.CALL_ID.includes("transfer")) {
          fetch("https://maxirest-monitor-api.hiperdevs.com/phone/transfer", {
            method: "POST",
            headers: {
              Authorization: `Basic ${bearerTokenHD}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ callCardStatus, userId }),
          });
        }
      });
    }
  });
};

runHDProcess();
