import * as React from "react";
import "./MonitorTable.css";
import Box from "@mui/material/Box";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import ActionsWidget from "../ActionsWidget/ActionsWidget";
import { useMonitorContext } from "../../providers/MonitorContext";
import { CallEvent, CallEvents } from "../../types/phoneType";
import {
  callLineNumberCalculation,
  callTimeInMS,
  callTypeCalculation,
  elapsedTimeCalculation,
  rowColorNumberCalculation,
  statusCalculation,
  timeToString,
} from "./tableHelpers";
import { Typography } from "@mui/material";

// const mockColors = rows.map((r) => ({
//   id: r.id,
//   colorNumber: Math.floor(Math.random() * 8) + 1,
// }));

type TableRow = {
  id: number;
  codCli?: number;
  company: string;
  operator: string;
  callTime: string;
  status: string;
  callType: string;
  lineNumber?: string;
};

export default function MonitorTable() {
  const [selectedRows, setSelectedRows] = React.useState<number[]>([]);
  const [rows, setRows] = React.useState<TableRow[]>();
  const [filteredRows, setFilteredRows] = React.useState<TableRow[]>();
  const {
    report,
    departmentsFilter,
    timemanRecords,
    timemanStatuses,
    formtimeRecords,
  } = useMonitorContext();
  const [currentDate, setCurrentDate] = React.useState<Date>(new Date());

  const columns: GridColDef[] = [
    { field: "codCli", headerName: "Cod_cli", width: 90 },
    {
      field: "company",
      headerName: "Compañía",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "operator",
      headerName: "Operador",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "callTime",
      headerName: "Tiempo transc.",
      minWidth: 110,
      flex: 1,
      valueGetter: (value) =>
        report &&
        elapsedTimeCalculation(
          report,
          value,
          timemanRecords,
          timemanStatuses,
          formtimeRecords,
          currentDate
        ),
      renderCell: (params) => (
        <Typography>{timeToString(params.value)}</Typography>
      ),
    },
    {
      field: "status",
      headerName: "Estado",
      minWidth: 130,
      flex: 1,
    },
    {
      field: "callType",
      headerName: "Tipo de llamada",
      minWidth: 150,
    },
    {
      field: "lineNumber",
      headerName: "Número de línea",
      minWidth: 150,
    },
  ];

  React.useEffect(() => {
    if (rows && report) {
      if (departmentsFilter) {
        setFilteredRows(
          rows.filter((r) =>
            report.users
              .find((u) => u.ID === r.id.toString())
              ?.UF_DEPARTMENT.some((d) =>
                departmentsFilter.deparments.includes(d)
              )
          )
        );
      } else {
        setFilteredRows(rows);
      }
    }
  }, [departmentsFilter, report, rows]);

  React.useEffect(() => {
    setInterval(() => {
      setCurrentDate(new Date());
    }, 1000);
  }, []);

  React.useEffect(() => {
    if (report) {
      setRows(
        report.users
          .sort((a, b) => (a.NAME > b.NAME ? 0 : -1))
          .map((u) => ({
            id: Number(u.ID),
            codCli: (report.calls as CallEvent[]).find(
              (c) => c.event === CallEvents.Start && c.data.USER_ID === u.ID
            )?.clientId,
            company:
              (report.calls as CallEvent[]).find(
                (c) => c.event === CallEvents.Start && c.data.USER_ID === u.ID
              )?.clientName || "",
            operator: `${u.NAME} ${u.LAST_NAME}`,
            callTime: u.ID,
            status: statusCalculation(
              report,
              u.ID,
              timemanRecords,
              timemanStatuses,
              formtimeRecords
            ),
            callType: callTypeCalculation(
              (report.calls as CallEvent[]).find(
                (c) => c.event === CallEvents.Start && c.data.USER_ID === u.ID
              )
            ),
            lineNumber: callLineNumberCalculation(
              (report.calls as CallEvent[]).find(
                (c) => c.event === CallEvents.Start && c.data.USER_ID === u.ID
              )
            ),
          }))
      );
    }
  }, [report, timemanRecords, timemanStatuses, formtimeRecords]);

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <DataGrid
          density="compact"
          rows={filteredRows}
          columns={columns}
          getRowId={(row) => row.id}
          // hideFooter
          checkboxSelection
          autoHeight
          getRowClassName={(params) =>
            report
              ? `reference-color-${rowColorNumberCalculation(
                  report,
                  callTimeInMS(
                    (report.calls as CallEvent[]).find(
                      (c) =>
                        c.event === CallEvents.Start &&
                        c.data.USER_ID ===
                          (params.row as TableRow).id.toString()
                    ),
                    currentDate
                  ),
                  timemanRecords,
                  timemanStatuses,
                  formtimeRecords,
                  (params.row as TableRow).id
                )}`
              : ""
          }
          rowSelectionModel={selectedRows}
          onRowSelectionModelChange={(rowSelectionModel) =>
            setSelectedRows(rowSelectionModel as number[])
          }
          loading={!rows}
          initialState={{
            pagination: { paginationModel: { pageSize: 100 } },
          }}
          pageSizeOptions={[100]}
        />
      </Box>
      {selectedRows.length ? (
        <ActionsWidget
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
        />
      ) : (
        <></>
      )}
    </>
  );
}
