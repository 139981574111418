import * as React from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { Stack } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import CircleIcon from "@mui/icons-material/Circle";

export default function MouseOverPopover() {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <Stack
        direction="row"
        alignItems="center"
        aria-owns={open ? "references-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <Typography>Referencias</Typography>
        <InfoIcon fontSize="small" />
      </Stack>
      <Popover
        id="references-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Stack p={1} gap={1}>
          <Stack direction="row" gap={1}>
            <CircleIcon sx={{ color: "#f9da78" }} />
            <Typography>0 a 15 minutos</Typography>
          </Stack>
          <Stack direction="row" gap={1}>
            <CircleIcon sx={{ color: "#f6ab76" }} />
            <Typography>16 a 25</Typography>
          </Stack>
          <Stack direction="row" gap={1}>
            <CircleIcon sx={{ color: "#bb271a" }} />
            <Typography>{"25 >"}</Typography>
          </Stack>
          <Stack direction="row" gap={1}>
            <CircleIcon sx={{ color: "#bdd6ac" }} />
            <Typography>Libre</Typography>
          </Stack>
          <Stack direction="row" gap={1}>
            <CircleIcon sx={{ color: "#a7c4e5" }} />
            <Typography>Chat bot</Typography>
          </Stack>
          <Stack direction="row" gap={1}>
            <CircleIcon sx={{ color: "#b7b7b7" }} />
            <Typography>Break</Typography>
          </Stack>
          <Stack direction="row" gap={1}>
            <CircleIcon sx={{ color: "#b7b7b7" }} />
            <Typography>Almuerzo</Typography>
          </Stack>
          <Stack direction="row" gap={1}>
            <CircleIcon sx={{ color: "#b7b7b7" }} />
            <Typography>Desactivado</Typography>
          </Stack>
        </Stack>
      </Popover>
    </div>
  );
}
