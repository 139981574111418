import * as React from "react";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import PhonePausedIcon from "@mui/icons-material/PhonePaused";
import PermPhoneMsgIcon from "@mui/icons-material/PermPhoneMsg";
import PhoneMissedIcon from "@mui/icons-material/PhoneMissed";
import CallIcon from "@mui/icons-material/Call";
import { useMonitorContext } from "../providers/MonitorContext";
import {
  CallCodes,
  CallEvent,
  CallEvents,
  CallTypes,
} from "../types/phoneType";
import { Popover, Typography } from "@mui/material";
import {
  callLineNumberCalculation,
  callTypeCalculation,
} from "./MonitorTable/tableHelpers";

export default function StatusResume() {
  const { report, departmentsFilter } = useMonitorContext();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  if (!report) {
    return <></>;
  }

  return (
    <Stack spacing={1} direction="row">
      <Chip
        aria-owns={open ? "waiting-calls-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        icon={<PhonePausedIcon />}
        label={`Llamadas en espera: ${
          (report.calls as CallEvent[]).filter(
            (c) =>
              (!departmentsFilter ||
                departmentsFilter.accountSearchId ===
                  c.data.ACCOUNT_SEARCH_ID) &&
              c.event === CallEvents.Init &&
              callTypeCalculation(c) === "Entrante"
          ).length
        } (Detalle)`}
        color="warning"
        sx={{ cursor: "pointer" }}
      />
      <Popover
        id="waiting-calls-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Stack p={1} gap={1}>
          <Typography variant="caption">
            Detalle de las llamadas en espera
          </Typography>
          {(report.calls as CallEvent[])
            .filter(
              (c) =>
                (!departmentsFilter ||
                  departmentsFilter.accountSearchId ===
                    c.data.ACCOUNT_SEARCH_ID) &&
                c.event === CallEvents.Init &&
                callTypeCalculation(c) === "Entrante"
            )
            .map((call) => (
              <Stack direction="row" gap={1}>
                <Typography>
                  {call.clientName} ({callLineNumberCalculation(call)})
                </Typography>
              </Stack>
            ))}
        </Stack>
      </Popover>
      <Chip
        icon={<PermPhoneMsgIcon />}
        label={`Llamadas atendidas: ${
          (report.calls as CallEvent[]).filter(
            (c) =>
              (!departmentsFilter ||
                departmentsFilter.accountSearchId ===
                  c.data.ACCOUNT_SEARCH_ID) &&
              (c.event === CallEvents.Start ||
                (c.event === CallEvents.End &&
                  c.data.CALL_FAILED_CODE === CallCodes.VI_STATUS_200)) &&
              [CallTypes.Inbound, CallTypes.InboundWithRedirection].includes(
                c.data.CALL_TYPE
              )
          ).length
        }`}
        color="info"
      />
      <Chip
        icon={<PhoneMissedIcon />}
        label={`Llamadas perdidas: ${
          (report.calls as CallEvent[]).filter(
            (c) =>
              (!departmentsFilter ||
                departmentsFilter.accountSearchId ===
                  c.data.ACCOUNT_SEARCH_ID) &&
              c.event === CallEvents.End &&
              [CallTypes.Inbound, CallTypes.InboundWithRedirection].includes(
                c.data.CALL_TYPE
              ) &&
              c.data.CALL_FAILED_CODE !== CallCodes.VI_STATUS_200
          ).length
        }`}
        color="error"
      />
      <Chip
        icon={<CallIcon />}
        label={`Llamadas totales: ${
          (report.calls as CallEvent[]).filter(
            (c) =>
              !departmentsFilter ||
              departmentsFilter.accountSearchId === c.data.ACCOUNT_SEARCH_ID
          ).length
        }`}
        color="info"
      />
    </Stack>
  );
}
