import * as React from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { Stack } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import PermPhoneMsgIcon from "@mui/icons-material/PermPhoneMsg";
import PhoneForwardedIcon from "@mui/icons-material/PhoneForwarded";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import ChatIcon from "@mui/icons-material/Chat";
import BathtubIcon from "@mui/icons-material/Bathtub";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import { useMonitorContext } from "../providers/MonitorContext";
import { calculateStatus, UserStatus } from "../helpers/userStatus";

export default function StatusesPopover() {
  const {
    report,
    timemanRecords,
    timemanStatuses,
    formtimeRecords,
    departmentsFilter,
  } = useMonitorContext();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [userStatuses, setUserStatuses] = React.useState<UserStatus[]>();

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  React.useEffect(() => {
    if (report) {
      const newUserStatuses: UserStatus[] = [];
      for (const user of report.users.filter(
        (u) =>
          !departmentsFilter ||
          u.UF_DEPARTMENT.some((d) => departmentsFilter.deparments.includes(d))
      )) {
        newUserStatuses.push(
          calculateStatus(
            report,
            timemanRecords,
            timemanStatuses,
            formtimeRecords,
            Number(user.ID)
          )
        );
      }
      setUserStatuses(newUserStatuses);
    }
  }, [
    report,
    timemanRecords,
    timemanStatuses,
    formtimeRecords,
    departmentsFilter,
  ]);

  if (!report || !userStatuses) {
    return <></>;
  }

  return (
    <div>
      <Stack
        direction="row"
        alignItems="center"
        aria-owns={open ? "references-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <Typography>Ver estados</Typography>
        <InfoIcon fontSize="small" />
      </Stack>
      <Popover
        id="references-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Stack p={1} gap={1}>
          <Stack direction="row" gap={1}>
            <PermPhoneMsgIcon />
            <Typography>
              Atendiendo:{" "}
              {
                userStatuses.filter((us) => us === UserStatus.InboundCall)
                  .length
              }
            </Typography>
          </Stack>
          <Stack direction="row" gap={1}>
            <PhoneForwardedIcon />
            <Typography>
              Saliente:{" "}
              {
                userStatuses.filter((us) => us === UserStatus.OutboundCall)
                  .length
              }
            </Typography>
          </Stack>
          <Stack direction="row" gap={1}>
            <PlayCircleIcon />
            <Typography>
              Libre:{" "}
              {userStatuses.filter((us) => us === UserStatus.Free).length}
            </Typography>
          </Stack>
          <Stack direction="row" gap={1}>
            <ChatIcon />
            <Typography>Chat bot: -</Typography>
          </Stack>
          <Stack direction="row" gap={1}>
            <RestaurantIcon />
            <Typography>
              Almuerzo:{" "}
              {userStatuses.filter((us) => us === UserStatus.Lunch).length}
            </Typography>
          </Stack>
          <Stack direction="row" gap={1}>
            <PauseCircleIcon />
            <Typography>
              Break:{" "}
              {userStatuses.filter((us) => us === UserStatus.Break).length}
            </Typography>
          </Stack>
          <Stack direction="row" gap={1}>
            <BathtubIcon />
            <Typography>
              Baño:{" "}
              {userStatuses.filter((us) => us === UserStatus.Bathroom).length}
            </Typography>
          </Stack>
          <Stack direction="row" gap={1}>
            <StopCircleIcon />
            <Typography>
              Desactivado:{" "}
              {
                userStatuses.filter((us) => us === UserStatus.Desactivated)
                  .length
              }
            </Typography>
          </Stack>
        </Stack>
      </Popover>
    </div>
  );
}
