export enum CallCodes {
  VI_STATUS_200 = "200",
  VI_STATUS_304 = "304",
  VI_STATUS_603 = "603",
  VI_STATUS_403 = "403",
  VI_STATUS_404 = "404",
  VI_STATUS_486 = "486",
  VI_STATUS_484 = "484",
  VI_STATUS_503 = "503",
  VI_STATUS_480 = "480",
  VI_STATUS_402 = "402",
  VI_STATUS_423 = "423",
  VI_STATUS_OTHER = "OTHER",
}

export enum CallTypes {
  Outbound = "1",
  Inbound = "2",
  InboundWithRedirection = "3",
  Callback = "4",
}

export enum CallEvents {
  Init = "ONVOXIMPLANTCALLINIT",
  Start = "ONVOXIMPLANTCALLSTART",
  End = "ONVOXIMPLANTCALLEND",
}

export type CallData = {
  // Call init
  CALL_ID: string;
  CALL_TYPE: CallTypes;
  ACCOUNT_SEARCH_ID: string;
  PHONE_NUMBER: string;
  CALLER_ID: string;
  // Call start
  USER_ID?: string;
  // Call end
  PORTAL_NUMBER?: string;
  PORTAL_USER_ID?: string;
  CALL_DURATION?: string;
  CALL_START_DATE?: string;
  COST?: string;
  COST_CURRENCY?: string;
  CALL_FAILED_CODE?: CallCodes;
  CALL_FAILED_REASON?: string;
  CRM_ACTIVITY_ID?: string;
};

export type CallEvent = {
  callId: string;
  event: CallEvents;
  data: CallData;
  ts: number;
  clientId: number;
  clientName: string;
};

export type CallCardStatus = {
  CALL_DIRECTION: "inbound" | "outbound" | "inboundTransfer" | "callback";
  CALL_ID: string; // Could include transfer.
  CALL_LIST_MODE: boolean;
  CALL_STATE: "idle" | "connecting" | "connected";
  CRM_BINDINGS: string[];
  CRM_ENTITY_ID: number;
  CRM_ENTITY_TYPE: "CONTACT" | "LEAD" | "COMPANY";
  LINE_NAME: string;
  LINE_NUMBER: string;
  PHONE_NUMBER: string;
};
