export type TrunkRelation = {
  accountSearchId: string;
  name: string;
  deparments: number[];
};

export const trunksRelations: TrunkRelation[] = [
  { accountSearchId: "reg56273", name: "Comercial", deparments: [80] },
  {
    accountSearchId: "reg56479",
    name: "Atención al Cliente",
    deparments: [17],
  },
  { accountSearchId: "reg58138", name: "Comercial UY", deparments: [80] },
  { accountSearchId: "reg59171", name: "Soporte Técnico", deparments: [23] },
  { accountSearchId: "reg59354", name: "PV", deparments: [29] },
  { accountSearchId: "reg60641", name: "Administración", deparments: [37] },
];

// Troncal Comercial = departamento Asignan vendedores y confirman ventas
// Troncal Atención al Cliente = departamento Atención al Cliente
// Troncal Comercial UY = departamento Asignan vendedores y confirman ventas
// Troncal Soporte Técnico = departamento Mesa de Ayuda
// Troncal PV = departamento Posventa
// Troncal Administración = departamento Administración
