import { Report } from "../../types/reportType";
import { CallEvent, CallEvents, CallTypes } from "../../types/phoneType";
import { TimeEvent, TimeEvents } from "../../types/timemanType";
import { TimemanStatus } from "../../bitrix/models/timemanEntity";
import { calculateStatus, UserStatus } from "../../helpers/userStatus";
import { FormEvents, FormtimeEvent } from "../../types/formtimeType";

export const callTimeInMS = (
  callEvent: CallEvent | undefined,
  currentDate: Date
) => {
  if (callEvent) {
    const startDate = new Date(callEvent.ts * 1000);
    return currentDate.getTime() - startDate.getTime();
  } else {
    return 0;
  }
};

export const timeToString = (ms: number): string => {
  if (ms) {
    const totalSec = Math.floor(ms / 1000);
    const totalMin = Math.floor(totalSec / 60);
    const totalHour = Math.floor(totalMin / 60);
    const totalDay = Math.floor(totalHour / 24);

    const seconds = totalSec % 60;
    const minutes = totalMin % 60;
    const hours = totalHour % 24;
    const days = totalDay;

    return `${days ? `${days}d ` : ""}${hours ? `${hours}hs ` : ""}${
      minutes ? `${minutes}m ` : ""
    }${seconds ? `${seconds}s ` : ""}`;
  } else {
    return "";
  }
};

export const elapsedTimeCalculation = (
  report: Report,
  userId: string,
  timemanRecords: TimeEvent[] | undefined,
  timemanStatuses: TimemanStatus[] | undefined,
  formtimeRecords: FormtimeEvent[] | undefined,
  currentDate: Date
): number => {
  const userStatus = calculateStatus(
    report,
    timemanRecords,
    timemanStatuses,
    formtimeRecords,
    Number(userId)
  );

  switch (userStatus) {
    case UserStatus.InboundCall:
    case UserStatus.OutboundCall:
      const callEvent = (report.calls as CallEvent[]).find(
        (c) => c.event === CallEvents.Start && c.data.USER_ID === userId
      );
      if (callEvent) {
        const startDate = new Date(callEvent.ts * 1000);
        return currentDate.getTime() - startDate.getTime();
      } else {
        return 0;
      }
    case UserStatus.Break:
    case UserStatus.Bathroom:
    case UserStatus.Lunch:
      const possibleTimeRecord = timemanRecords
        ?.sort((a, b) => b.ts - a.ts)
        .find((tr) => tr.data.userIds.includes(Number(userId)));
      if (possibleTimeRecord?.event === TimeEvents.Pause) {
        const startDate = new Date(possibleTimeRecord.ts * 1000);
        return currentDate.getTime() - startDate.getTime();
      }
      return 0;
    case UserStatus.Form:
      const possibleFormRecord = formtimeRecords
        ?.sort((a, b) => b.ts - a.ts)
        .find((tr) => tr.data.userId === Number(userId));
      if (possibleFormRecord?.event === FormEvents.Started) {
        const startDate = new Date(possibleFormRecord.ts * 1000);
        return currentDate.getTime() - startDate.getTime();
      }
      return 0;
    default:
      return 0;
  }
};

export const statusCalculation = (
  report: Report,
  userId: string,
  timemanRecords: TimeEvent[] | undefined,
  timemanStatuses: TimemanStatus[] | undefined,
  formtimeRecords: FormtimeEvent[] | undefined
) =>
  calculateStatus(
    report,
    timemanRecords,
    timemanStatuses,
    formtimeRecords,
    Number(userId)
  );

export const callTypeCalculation = (
  callEvent: CallEvent | undefined
): string => {
  if (callEvent) {
    switch (callEvent.data.CALL_TYPE) {
      case CallTypes.Inbound:
        return "Entrante";
      case CallTypes.Outbound:
        return "Saliente";
      case CallTypes.InboundWithRedirection:
        return "Entrante";
      case CallTypes.Callback:
        return "Saliente";
    }
  } else {
    return "";
  }
};

export const callLineNumberCalculation = (callEvent: CallEvent | undefined) => {
  if (callEvent) {
    if (
      [CallTypes.Inbound, CallTypes.InboundWithRedirection].includes(
        callEvent.data.CALL_TYPE
      )
    ) {
      return callEvent.data.CALLER_ID;
    } else {
      return callEvent.data.PHONE_NUMBER;
    }
  } else {
    return "";
  }
};

export const rowColorNumberCalculation = (
  report: Report,
  ms: number | undefined,
  timemanRecords: TimeEvent[] | undefined,
  timemanStatuses: TimemanStatus[] | undefined,
  formtimeRecords: FormtimeEvent[] | undefined,
  userId: number
) => {
  if (ms && ms > 0) {
    if (ms < 900000) {
      return 1;
    } else if (ms < 1.5e6) {
      return 2;
    } else {
      return 3;
    }
  } else {
    const userStatus = calculateStatus(
      report,
      timemanRecords,
      timemanStatuses,
      formtimeRecords,
      userId
    );
    if (userStatus === UserStatus.Free) {
      return 4;
    } else if (userStatus === UserStatus.Break) {
      return 6;
    } else if (userStatus === UserStatus.Desactivated) {
      return 8;
    } else if (userStatus === UserStatus.Form) {
      return 6;
    } else {
      return 9;
    }
  }
};
